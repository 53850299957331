import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../Components/Header/Header';
import SideBar from './../../admin/Components/SideBar';

import './Blog.css'; 

const Blog = () => {
  const [currentView, setCurrentView] = useState('Dashboard');
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [showText, setShowText] = useState(window.innerWidth > 920);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 920) {
        setCollapsed(true); 
      } else {
        setCollapsed(false);  
      }
      setShowText(window.innerWidth > 920); 
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const filterCondition = (navItem) => {
    return navItem._id >= 200 && navItem._id <= 299; 
  };
  
  const handleToggleSidebar = () => {
    setCollapsed(prevState => !prevState);
  };

  const centeredTextWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    overflowWrap: 'normal',
    marginLeft: '65px',
    paddingLeft: 'auto',
    paddingRight: 'auto',
  };
  
  
  const centeredNumeralStyle = {
    diplay: 'flex',
    flexDirection: 'column',
    marginTop:'10px',
    marginBottom: '-18px',
    alignItems: 'justify',
    paddingLeft: '13%',
    wordWrap: 'beak-word',
    paddingRight: '10%',
    
  };
  return (
    <div className="blog-container">
      <Helmet>
        <title>Articles | Contranorm Media & Production</title>
        <meta name="description" content="Gain insight into the MSPB administrative hearing process. Learn how to handle an employment dispute or grievance by understanding your rights as a federal employee before the Merit Systems Protection Board." />
        <meta name="keywords" content="administrative hearings, MSPB, federal employee, employment disputes, AFGE, union representation, informal resolution, formal grievance, unlawful termination, prohibited personnel action, document preparation, how to prepare" />
        <link rel="canonical" href="https://contranormdigital.com/articles" />
      </Helmet>
      
      <Header className="header" />
        
      <div className="stripe"></div>
      
      <div className={`sidebar ${showText ? 'expanded' : 'collapsed'}`}>
        <SideBar  
          onNavClick={scrollToSection} 
          filterCondition={filterCondition} 
          className="sidebar" 
          collapsed={collapsed}
          onToggleSidebar={handleToggleSidebar} 
        />
      </div>
      
      <div className={`content-container ${collapsed ? '' : 'expanded'}`}>
        
         <div className="AdministrativeHearing" id="Administrative Hearing">
          <br /><br />
              <h2>Administrative Hearings</h2>
                <p>An administrative hearing is not a criminal or civil court case.  It is not a traditional civil court proceeding, and it is non-criminal in nature.  Similar to civil cases, administrative hearings will not result in imprisonment, criminal charges, or other criminal penalties. Instead, the purpose of an administrative hearing is to resolve a dispute that one or more individuals may have with a state or federal entity.
                </p>
            
              <p>So, while administrative hearings are not technically "civil proceedings" and do not take place in a traditional court setting, they do share some similarities.  One thing they have in common is the expectation to follow procedural rules.  These rules are publicly available, and do not require a licensed attorney to access or understand them. Another similarity is that anyone, who has a direct and personal interest, can file a complaint.  Finally, like traditional court cases, the whole process is overseen by an appointed officer or an Administrative Law Judge.</p>
              <br />
              <div style={centeredTextWrapperStyle}>
                <h2><strong>Q.&nbsp;Why do I need to attend an administrative hearing?</strong></h2>
                <p> If you are a state or federal employee, a qualifying exempted service employee, or an individual recently separated from employment from a state or federal agency, or if you have been identified as a witness who can share relevant testimony on behalf of any of the aforementioned parties, you may be summoned to participate in an administrative hearing.
                </p><br  />
                <p>If you have been working for a state or federal agency and feel that you have been unlawfully suspended, terminated, removed, relocated, reassigned, demoted, disciplined, or discriminated against-- then you may challenge that determination in an administrative court.</p>
                <br  />
                <p>If you work for a government agency and you believe that a member of management took an employment action or failed to take an employment action, unlawfully, then you may challenge that determination in an administrative court. </p>
                <br />
                <p>Additionally, businesses or individuals, who are not employed or former employees of the state or the federal government, may also challenge a regulatory decision, to include enforcement actions, made by a state or federal agency in an administrative proceeding.</p>
                <br />
                <h2><strong>Q.&nbsp;How can I get an Administrative Judge to hear my complaint?</strong></h2>
                <p>Depending on the nature of your complaint, employment status, and whether or not you work for the state or federal government, your options may differ.  For example, state workers cannot file a complaint with a centralized board, particularly the Merit Systems Protection Board, since each state has its own boards, commissions, and agencies designed to handle disputes.  Instead, state workers must resolve disputes with their employer using the dispute-resolution method specific to their state and employing agency. </p>
                <br />
                <p>Fortunately, federal workers don't have to deal with the hassle of figuring out which agency or department, commission, or board is responsible for hearing their employment-related dispute, as all of this can be addressed by the Merit Systems Protection Board. </p>
              </div>
              <br /><br />
              <div id="MSPB">
            
                <br />
                <h2>The Merit Systems Protection Board</h2>
                <br />
                <p>The Merit Systems Protection Board (MSPB) provides federal workers with a much more predictable outcome than a state-level administrative hearing. This predictability comes from the extensive body of decisions published by the MSPB, which offer consistent guidance on the application, interpretation, and enforcement of federal laws.</p> 
                <p>State-level disputes, on the contrary, may be subject to interpretations and procedural practices that may vary from state to state, and this is what leads to less uniformity in the application or interpretation of rules and laws. 
                
                Therefore, since the laws that influence an MSPB decision are not created at the state level, the approach to resolving an employment-related dispute is much more reliable when dealing with the Merit Systems Protection Board. 
                </p>
                <br />
                <div style={centeredTextWrapperStyle}>
                <br />
                 
                 <h2><strong>Q.&nbsp;What is MSPB?</strong></h2>
                 <p>MSPB is an independent federal agency. Its "Board" is comprised of three members that were chosen by the President of the United States. These individuals are not required to be legal professionals, they are, however, expected to have relevant experience and expertise in areas important to the Merit Systems Protection Board's functions. So, after an appointment is made by the President, the Senate must agree with the appointment, and if it does, the appointment is confirmed.</p>
                 <br />
                 <p>The appointment is a federal position. This means that MSPB Board members are federal employees. However, the expectation is for these three members to select and appoint Administrative Judges to hear the cases filed with MSPB.</p>
                 
                 <br />
                 <h2><strong>Q.&nbsp;Who can challenge the Administrative Judge?</strong></h2>
                 <p>Aside from appointing Administrative Judges to hear incoming cases, the "Board" will also handle a "Petition for Review." A "Petition for Review" is a new case that challenges an Administrative Judge's "Initial Decision," and typically occurs when either party disputes the Administrative Judge's verdict.</p>
                 <br />
                 <p>Both parties generally have thirty-five days to challenge an Administrative Judge's "Initial Decision." However, it can take the "Board" anywhere from two to five years to examine a "Petition for Review." Nonetheless, once the "Board" arrives at its decision, it will issue a verdict called a "Final Decision." This determination cannot be contested at the Board level, since the ruling itself serves as the Merit Systems Protection Board's position on the matter. The "Final Decision" will have one of four possible outcomes: "Affirm," "Reverse," "Modify," or "Remand." This means that the "Initial Decision" made by the Administrative Judge will either be upheld, overturned, changed, or sent back for further adjudication.</p>
                 <br />
                 <h2><strong>Q.&nbsp;How can a Final Decision be revoked?</strong></h2>
                 <p>A "Final Decision" cannot be challenged within the Merit Systems Protection Board, but it can be overturned outside the administrative-adjudication process under certain circumstances. The options at this point are limited. For example, only the appellant, the Office of Personnel Management (OPM), or the Office of Special Counsel (OSC) has the right to seek judicial review of an MSPB "Final Decision."  This means that the Agency involved in the dispute cannot contest an MSPB "Final Decision" in a federal court, but the appellant can.</p>
                 <br />
                 <p>The federal law that establishes the rights of the appellant is outlined in the United States Code of Federal Regulations, specifically Chapter 5, Section 7703(a)(1), which cites "Any employee or applicant for employment, adversely affected or aggrieved by a final order or decision of the Merit Systems Protection Board, may obtain judicial review of the order or decision." In addition to this, 7703(b)(1) establishes, "Except as provided in paragraph (2) of this subsection, a petition to review a final order or final decision of the Board shall be filed in the United States Court of Appeals for the Federal Circuit or any court of appeals of competent jurisdiction within 60 days after the Board issues notice of the final order or decision."</p>
                 <br />
                 <p>The federal law that clarifies the rights of the OSC is found in Section 1214(a)(3) of the same chapter, which declares, "The Special Counsel may seek judicial review of any final order or decision of the Merit Systems Protection Board, in any matter, involving an alleged prohibited personnel practice described in Section 2302(b)(8)."</p>

                </div>
                
              </div>
            
              <div id="Action Required">
                <br />
                <h2>You Are Your Most Valuable Asset</h2>
                <br />
                <p> Pursuing a complaint involves navigating complex processes, and work-related grievances may make identifying the right protocol or meeting the correct deadline challenging, which could put your case at risk of being time-barred. While we offer in-depth assistance and ongoing advocacy, please note that we are not licensed attorneys. We do not provide legal advice, and any information, commentary, educational material, or observation that we offer is not a legal explanation, legal guidance, or an interpretation of the law. It is not intended, nor should it be used to substitute, supplant, or circumvent advice from a qualified legal professional. For legal advice, consult a qualified, licensed attorney.</p>
              <br />
                <p>In the interim, it's important to note that the election of the Union grievance process generally forfeits your right to the MSPB process. Similarly, if you have exhausted all the remedies under your union's grievance procedure, and didn't get the outcome you were looking for, you generally aren't able to file the same complaint or seek relief from the Merit Systems Protection Board, unless your case is a "mixed case."  A mixed case is one that involves claims of discrimination and an appealable adverse employment action. So, if you have a mixed case, and you can prove that your Union mishandled the complaint, did not pursue the complaint because it was not filed properly, or if you have documentation that demonstrates that your grievance has not been fully adjudicated due to procedural error(s)—then you may still have grounds to have your mixed case heard by an Administrative Judge.
                </p>
                <br />
                <p>So, if you're a federal employee seeking assistance with an employment-related issue or complaint, we encourage you to pursue the outlet that will increase your chances of obtaining the relief or remedy you seek. However, if you believe that you do not need an attorney or if you are unable to afford one and want to handle your own case, Contranorm Media & Production offers document preparation, research, and other premium services, tailored to fit your needs, so you can focus on winning your case.
                </p>
            
              </div>
              
            </div>
      </div>
    </div>
  );
};

export default Blog;
