import React, { useState, useEffect, useRef } from 'react';
import './RegisterDeliverable.css'; 
import axios from 'axios';
import axiosInstance from '../../axiosInstance';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';


const API_URL = process.env.REACT_APP_API_URL;


const Feedback = ({ onClose }) => {
  const [currentView, setCurrentView] = useState('register');
  const [lastLogin, setLastLogin] = useState(null);
  const [email, setEmail] = useState('');
  const [googleDriveLink, setGoogleDriveLink] = useState('');
  const uuid = uuidv4();
  const [searchFeedback, setSearchFeedback] = useState('');
  const [feedbackData, setFeedbackData] = useState(null);
  const [loading, setLoading] = useState(false);
 

  
 

  const handleToggleClick = (view) => {
    setCurrentView(view);
  };

  const getLastLogin = async (email) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/contact`, {
        params: { email }
      });
      console.log('Login response:', response.data); 
      setLastLogin(response.data);
    } catch (error) {
      console.error('Error fetching login information:', error);
      setLastLogin(null);  // Set to null in case of error
    } finally {
      setLoading(false);
    }
  };


  const getFeedback = async () => {
    setLoading(true);
    try {
      const fileId = googleDriveLink.split('/d/')[1].split('/')[0];
      const response = await axios.get(`${API_URL}/api/Deliverable/feedback`, {
        params: { email, googleDriveID: fileId },
      });
      setFeedbackData(response.data);
      getLastLogin(email); 
      
    } catch (error) {
      alert('Feedback not found.', error);
      setFeedbackData(null);
    } finally {
      setLoading(false); // Set loading to false once the request is complete
    }
  };

  
 

  useEffect(() => {
    if (searchFeedback.trim()) {
      getFeedback();
    } else {
      setFeedbackData(null); // Clear quiz data if ID input is cleared
    }
  }, [searchFeedback]);

  useEffect(() => {
    if (email && googleDriveLink) {
      getFeedback();  // Call getFeedback if both fields have values
    }
  }, [email, googleDriveLink]); 

 

  const handleRegisterFormSubmit = async () => {
    const form = document.querySelector('#register-form');
    if (form.checkValidity()) {
      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
  
      console.log('Form data:', data); // Log the form data
  
      try {
        const response = await axios.post(`${API_URL}/api/Deliverable`, data);
        alert('Submission successful:', response.data);
        onClose();
      } catch (error) {
        alert('Error:', error.response ? error.response.data : error.message);
      }
    } else {
      form.reportValidity();
    }
  };
  
  
  

  return (
    <div className="register-Deliverable">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>x</button>
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${currentView === 'register' ? 'active' : ''}`}
            onClick={() => handleToggleClick('register')}
          >
            Close Order
          </button>
          <button
            className={`toggle-button ${currentView === 'search' ? 'active' : ''}`}
            onClick={() => handleToggleClick('search')}
          >
            Get Feedback
          </button>
        </div>
  
        <div className="form-container">
          {currentView === 'register' ? (
            <form id="register-form" onSubmit={handleRegisterFormSubmit}>
               <input type="text" name="email" placeholder="Email" required />
               <input type="url" name="googleDriveLink" placeholder="Google Drive Link" required />
                <button type="submit" disabled={loading}>
                Submit
                </button>
               
            </form>
          ) : (
            <div className="search-quiz">
              {!feedbackData ? (
                <>
                  <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      value={email}  // Bind state for email input
                      onChange={(e) => setEmail(e.target.value)}  // Update email state
                  />
                <input
                      type="url"
                      name="googleDriveLink"
                      placeholder="Google Drive Link"
                      required
                      value={googleDriveLink}  // Bind state for googleDriveLink input
                      onChange={(e) => setGoogleDriveLink(e.target.value)}  // Update googleDriveLink state
                  />
                </>
                ) : (
                  <div className="edit-quiz">
                <h3></h3>
                {/* Display Last Login Information */}
                {lastLogin && (
                  <div className="last-login-info">
                    <h4>Last Login</h4>
                    {loading ? (
                      <p>Loading...</p>
                    ) : lastLogin ? (
                      <div>
                        <p className="last-login">
                          <span className="last-login-label"></span>
                          <span
                            className={`last-login-date ${
                              moment().diff(moment(lastLogin.LastLogin), "hours") <= 24
                                ? "recent-login"
                                : ""
                            }`}
                          >
                             {lastLogin[0].LastLogin
                               ? moment(lastLogin[0].LastLogin)
                                  .tz("America/New_York")
                                  .format("MMMM D, YYYY h:mm:ss A")
                              : "No Last Login Available"}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <p>No Last Login Information</p>
                    )}
                    {/* Render feedback data (stickyNotes or empty if not found) */}
                    <pre>{JSON.stringify(feedbackData, null, 2)}</pre>
                  </div>
                )}
                
                </div>
              )}
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
  
};

export default Feedback;
