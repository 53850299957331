import React, { useState, useEffect, useRef } from 'react';
import './RegisterDeliverable.css'; 
import axios from 'axios';
import axiosInstance from '../../axiosInstance';

import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'bootstrap';

const API_URL = process.env.REACT_APP_API_URL;
const DEBOUNCE_DELAY = 1000; 

const SAT = ({ onClose }) => {
  const [currentView, setCurrentView] = useState('register');
  const [moduleNumber, setModuleNumber] = useState('');
  const [quizName, setQuizName] = useState('');
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [moduleUploaded, setModuleUploaded] = useState(false); 
  const uuid = uuidv4();
  const [searchQuizID, setSearchQuizID] = useState('');
  const [quizData, setQuizData] = useState(null);
  const [editJson, setEditJson] = useState('');
  const [editHistory, setEditHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const textareaRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [autoSaveMessage, setAutoSaveMessage] = useState('');
  const [isJsonValid, setIsJsonValid] = useState(true);
  const [viewOnly, setViewOnly] = useState(true);
  const [lastValidJson, setLastValidJson] = useState(editJson);
  const [storedPage, setStoredPage] = useState(null);
  

  const toggleEditMode = () => {
    const textarea = textareaRef.current;
    
    if (viewOnly) {
      // Save scroll position before switching to edit
      setStoredPage(textarea.scrollTop);
    } else {
      // Restore scroll position when toggling back to view
      textarea.scrollTop = storedPage;
    }
    
    setViewOnly(!viewOnly);
  };
  
  

  const handlePageDown = () => {
    setCurrentPage((prev) => {
      const newPage = prev + 13;
      textareaRef.current.scrollTop = newPage * 20; // Adjust 20 based on line height
      return newPage;
    });
  };
  
  const handlePageUp = () => {
    setCurrentPage((prev) => {
      const newPage = Math.max(prev - 13, 0);
      textareaRef.current.scrollTop = newPage * 20; // Adjust 20 based on line height
      return newPage;
    });
  };
  const handleEditChange = (e) => {
    setEditHistory((prevHistory) => [...prevHistory, editJson]); 
    setEditJson(e.target.value);
    setIsJsonValid(true);
    setAutoSaveMessage('');
  };
  const handleUndo = (e) => {
    if (e.ctrlKey && e.key === 'z') {
      e.preventDefault();
      if (editHistory.length > 0) {
        const lastEdit = editHistory.pop();
        setEditJson(lastEdit);
        setEditHistory([...editHistory]);
      }
    }
  };
  


 
  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return; // Exit if textareaRef is not yet defined
  
    const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartX(e.pageX - textarea.offsetLeft);
      setScrollLeft(textarea.scrollLeft);
      document.body.style.cursor = 'grabbing'; // Global grabbing cursor
    };
  
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - textarea.offsetLeft;
      const walk = (x - startX) * 1; // Adjust scroll speed here
      textarea.scrollLeft = scrollLeft - walk;
    };
  
    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.style.cursor = 'auto'; // Reset cursor globally
    };
  
    textarea.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  
    return () => {
      textarea.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);
  
  
 

  const handleToggleClick = (view) => {
    setCurrentView(view);
  };

  const handleFileChange = (event) => {
    const uploadedFiles = event.target.files;
    let newFiles = [...files];
    let total = totalSize;

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      const fileSizeMB = file.size / (1024 * 1024);

      if (!file.name.endsWith('.txt')) {
        alert(`Only .txt files are allowed: ${file.name}`);
        continue;
      }

      if (total + fileSizeMB > 10) {
        alert(`Total file size exceeds the 10MB limit. You can only upload up to ${(10 - total).toFixed(2)} MB more.`);
        break;
      }

      newFiles.push(file);
      total += fileSizeMB;
    }

    setFiles(newFiles);
    setTotalSize(total);
  };

  const handleSetQuizName = (name) => {
    setQuizName(name.toUpperCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
        alert('Please select a file to upload.');
        return;
    }
    if (!moduleNumber) {
      alert('Please select a module number.');
      return;
    }

    setLoading(true);
    setUploadStatus(`Converting ${moduleNumber}...`);

    const formData = new FormData();
    formData.append('uuid', uuid);
    formData.append('quizName', quizName);
    formData.append('module', moduleNumber);

    // Append each file to FormData
    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
        
      const response = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


        if (response.status === 200) {
            setUploadStatus(`${moduleNumber} uploaded successfully!`);
            setModuleUploaded(moduleNumber === 'Module 1');
            setFiles([]);  
            setTotalSize(0); 
            if (moduleNumber === 'Module 2') {
              setUploadStatus('Both modules uploaded successfully!');
              setModuleUploaded(false); // Reset after both modules are uploaded
            }
          } else {
            setUploadStatus('Failed to upload files.');
        }
      } catch (error) {
          console.error('Error during upload:', error);
          setUploadStatus('Failed to upload files.');
      } finally {
          setLoading(false);
      }
  };

  const fetchQuizByID = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/upload/search-quiz`, {
        params: { quizID: searchQuizID },
      });
      setQuizData(response.data);
      setEditJson(JSON.stringify(response.data, null, 2)); // Format for editing
    } catch (error) {
      console.error('Quiz not found or error fetching quiz data:', error);
      setQuizData(null);
    }
  };
  const handleSaveEdit = async () => {
    try {
      const response = await axiosInstance.put(
        `${API_URL}/upload/update-quiz`, 
        {
          quizID: searchQuizID,
          jsonData: JSON.parse(editJson),
        },
        {
          validateStatus: function (status) {
            return status === 200 || status === 204;
          }
        }
      );
      if (response.status === 200 && response.data.success) {
        alert(response.data.message || 'Quiz JSON updated successfully!');
      } else {
        alert('Not saving');
      }
  
    } catch (error) {
      console.error('Error updating quiz data:', error);
      alert('Quiz JSON updated successfully!');
 
    }
  };
  
  useEffect(() => {
    const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  }, []);


  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('keydown', handleUndo);
      return () => textarea.removeEventListener('keydown', handleUndo);
    }
  }, [editHistory]);

 

  useEffect(() => {
    if (searchQuizID.trim()) {
      fetchQuizByID();
    } else {
      setQuizData(null); // Clear quiz data if ID input is cleared
    }
  }, [searchQuizID]);

  useEffect(() => {
    if (!editJson) return;
  
    const handler = setTimeout(async () => {
      try {
        setAutoSaveMessage('Auto-save successful!');
        const parsedJson = JSON.parse(editJson);
  
        if (lastValidJson !== editJson) {
          const response = await axiosInstance.put(
            `${API_URL}/upload/update-quiz`,
            {
              quizID: searchQuizID,
              jsonData: parsedJson,
            },
            {
              validateStatus: (status) => status === 200 || status === 204,
            }
          );
  
          if (response.status === 200 || response.status === 204) {
            setLastValidJson(editJson);
          }
        } else {
          setAutoSaveMessage('');
        }
  
        setIsJsonValid(true);
      } catch (error) {
        if (error.sessionExpired) {
          setAutoSaveMessage('Session expired, please log in again.');
        } else if (error.fileGone) {
          setAutoSaveMessage('File no longer available.');
        } else if (error instanceof SyntaxError) {
          setIsJsonValid(false);
          setAutoSaveMessage('Invalid JSON format. Please correct syntax.');
        }
        // No message for other errors
      } finally {
        setTimeout(() => setAutoSaveMessage(''), 2000);
      }
    }, DEBOUNCE_DELAY);
  
    return () => clearTimeout(handler);
  }, [editJson, lastValidJson]);
  
  
  

  return (
    <div className="register-Deliverable">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>x</button>
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${currentView === 'register' ? 'active' : ''}`}
            onClick={() => handleToggleClick('register')}
          >
            Register Quiz
          </button>
          <button
            className={`toggle-button ${currentView === 'search' ? 'active' : ''}`}
            onClick={() => handleToggleClick('search')}
          >
            Search Quiz
          </button>
        </div>
  
        <div className="form-container">
          {currentView === 'register' ? (
            <form id="register-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="quizName"
                placeholder="Quiz Name"
                required
                value={quizName}
                onChange={(e) => handleSetQuizName(e.target.value)}
              />
              <select name="moduleNumber" onChange={(e) => setModuleNumber(e.target.value)} required>
                <option value="" disabled selected>Module Number</option>
                <option value="Module 1">Module 1</option>
                <option value="Module 2">Module 2</option>
              </select>
              <input
                type="file"
                id="file-input"
                accept=".txt"
                multiple
                onChange={handleFileChange}
                required
              />
              <label htmlFor="file-input">Choose Files to Upload</label>
              <div id="num-of-files">{files.length} Files Selected ({totalSize.toFixed(2)} MB)</div>
              <div className="upload-status">
                {loading ? (
                  <p>{uploadStatus}</p>
                ) : (
                  <button type="submit" disabled={loading}>
                    Submit
                  </button>
                )}
              </div>
            </form>
          ) : (
            <div className="search-quiz">
              <input
                type="text"
                placeholder="Enter QUIZ_ID"
                value={searchQuizID}
                onChange={(e) => setSearchQuizID(e.target.value)}
              />
              {quizData && (
                <div className="edit-quiz">
                <textarea
                  ref={textareaRef}
                  rows="15"
                  value={editJson} // Display full content in both view and edit modes
                  onChange={viewOnly ? undefined : handleEditChange}
                  style={{ cursor: viewOnly ? 'default' : 'text' }}
                  className={`draggable-textarea ${isJsonValid ? '' : 'error-border'}`}
                />

          
                  {/* Toggle Button Above Pagination */}
                  <div className="toggle-pill">
                    <button
                      onClick={toggleEditMode}
                      data-bs-toggle="tooltip"
                      title={viewOnly ? 'Switch to Edit Mode' : 'Switch to View Mode'}
                      className="toggle-pill-btn"
                    >
                      {viewOnly ? (
                        <i className="ri-eye-line"></i> // Eye icon for view mode
                      ) : (
                        <i className="ri-settings-2-line"></i> // Gear icon for edit mode
                      )}
                    </button>
                  </div>
          
                  {/* Pagination Buttons (Always Visible) */}
                  <div className="pagination-buttons">
                    <button onClick={handlePageUp} disabled={currentPage === 0}>
                      <i className="bi bi-chevron-up"></i>
                    </button>
                    <button onClick={handlePageDown}>
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </div>
          
                  <div className={`auto-save-message ${isJsonValid ? 'auto-save-success' : 'auto-save-error'}`}

                      >{autoSaveMessage}</div>
                </div>
              )}
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
  
};

export default SAT;
