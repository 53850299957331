import React from 'react';
import './SideBar.css';
import NavItem from './NavItem';
import navList from '../DataStatic/navList';
import { FaFileAlt, FaBullseye, FaEye, FaLifeRing, FaShieldAlt, 
  FaCheck, FaTimes, FaAward, FaGavel, FaExclamationTriangle, FaStamp,  FaCog, FaMicrophoneAlt, FaPenAlt, FaPaperPlane, FaCloud, FaUser, FaFlag, FaChessKnight, FaLock, FaAtom,
} from 'react-icons/fa';

function SideBar({ onNavClick, filterCondition,  onToggleSidebar, collapsed }) {
  const handleNavClick = (name) => {
    onNavClick(name);
  };
  const appliedFilterCondition = typeof filterCondition === 'function' ? filterCondition : () => true;
  const filteredNavList = navList.filter(appliedFilterCondition);

  const getIcon = (navItem) => {
    const iconMap = {
      'Privacy': <FaLock className="client-sidebar-icon" />,
      'Terms & Conditions': <FaFileAlt className="client-sidebar-icon" />,
      'Mission': <FaBullseye className="client-sidebar-icon" />,
      'Vision': <FaEye className="client-sidebar-icon" />,
      'Support': <FaLifeRing className="client-sidebar-icon" />,
      'DCMA': <FaShieldAlt className="client-sidebar-icon" />,
      'Check': <FaCheck className="client-sidebar-icon" />,
      'Times': <FaTimes className="client-sidebar-icon" />,
      'Ownership': <FaAward className="client-sidebar-icon" />,
      'Code of Conduct': <FaGavel className="client-sidebar-icon" />,
      'Termination': <FaExclamationTriangle className="client-sidebar-icon" />,
      'Copyright': <FaStamp className="client-sidebar-icon" />,
      'Block User': <FaFlag className="client-sidebar-icon" />,
      'Contacts': <FaUser className="client-sidebar-icon" />,
      'Upload Quiz': <FaCloud className="client-sidebar-icon" />,
      'SAT': <FaEye className="client-sidebar-icon" />,
      'Deliverable': <FaPaperPlane className="client-sidebar-icon" />,
      'Feedback': <FaPenAlt className="client-sidebar-icon" />,
      'Settings': <FaCog className="client-sidebar-icon" />,
      'Live Chat': <FaMicrophoneAlt className="client-sidebar-icon" />,
      'Administrative Hearing': <FaChessKnight className="client-sidebar-icon" />,
      'MSPB': <FaGavel className="client-sidebar-icon" />,
      'Documentation': <FaFileAlt  className="client-sidebar-icon" />,
      'Action Required': <FaAtom className="client-sidebar-icon" />,
     
    };
  
    // Check if the navItem._id > 7, return corresponding icon if present
    return iconMap[navItem.name] || null;
  };
  


  return (
    <aside id="sidebar" className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <ul className="sidebar-nav" id="sidebar-nav">
        {filteredNavList.map(nav => (
          <NavItem 
          key={nav._id}
          nav={nav} 
          onClick={() => handleNavClick(nav.name)} 
          icon={getIcon(nav)}
          collapsed={collapsed}
          />
        ))}
      </ul>
       
       <div className="sidebar-toggle-btn" onClick={onToggleSidebar}
       style={{ pointerEvents: collapsed && window.innerWidth <= 920 ? 'none' : 'auto' }}>

        <FaTimes className="toggle-icon" />
      </div>
    </aside>
  );
}

export default SideBar;
