// navList.js
const navList = [
    { _id: 1, name: 'Block User'},
    { _id: 2, name: 'Contacts'},
    { _id: 3, name: 'Deliverable'},
    { _id: 4, name: 'Feedback'},
    { _id: 5, name: 'Live Chat'},
    { _id: 6, name: 'Upload Quiz'},
    { _id: 7, name: 'Settings'},
    { _id: 100, name: 'Mission'},
    { _id: 101, name: 'Vision'},
    { _id: 102, name: 'Ownership'},
    { _id: 103, name: 'Terms & Conditions'},
    { _id: 104, name: 'Code of Conduct'},
    { _id: 105, name: 'Privacy'},
    { _id: 106, name: 'Support'},
    { _id: 107, name: 'Termination'},
    { _id: 108, name: 'Copyright'},
    { _id: 109, name: 'DCMA'},
    { _id: 201, name: 'Administrative Hearing'},
    { _id: 202, name: 'MSPB'},
    { _id: 203, name: 'Documentation'},
    { _id: 204, name: 'Action Required'},
  ];
  
  export default navList;
  